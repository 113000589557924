import React  from 'react'
import { Link } from 'gatsby'
import { useBilderDataQuery } from 'Hooks/useBilderQuery'

// Styles
import { FooterWrapper} from "./Footer.styles"

//Hooks

// {footerQuery.tjanst.map(item => (
//     <Link to={item.link} key={item.id}>
//         {item.name}
//     </Link>
// )) }

// import { useFooterMetaQuery } from 'Hooks/useFooterQuery'

const Footer = () => { 

    // const footerQuery = useFooterMetaQuery()
   const { 
        adplast,
        iconone
    } = useBilderDataQuery()
   const adplastbild = adplast.childImageSharp.base64.base64;
   const form = iconone.childImageSharp.base64.base64;

    return(
        
       
    <FooterWrapper>
        <div className="footer-top" id="footer">
            <p>Om du vill ansluta ditt företag måste du ha yrkestrafiktillstånd 
            och jobbar aktivt inom städbranchen. 
            Du är välkommen att kontakta oss via vårt formulär på <Link to="/kontakt" title="Kontakt">kontakt</Link> sidan.
            Läs mer om 
            <Link to="/hemstad-boras" title="Hemstäd Borås"> Hemstäd, </Link>
            <Link to="/trappstadning" title="Trappstadning Borås"> Trappstadning, </Link>
            <Link to="/flyttstadning" title="Flyttstädning Borås">Flyttstädning, </Link>
            <Link to="/fonsterputs" title="Trappstadning Borås"> Fönsterputs, </Link>
            <Link to="/butiksstadning" title="Butiksstadning Borås"> Butiksstadning. </Link>

            </p>
            <a href="https://www.foretagsbloggar.nu/">
                <img src="https://www.foretagsbloggar.nu/button.php?u=tonicaktas" alt="foretagsbloggar.nu"/>
            </a>
           

        </div>
    </FooterWrapper>
    
    )
    
}

export default Footer

