import { useStaticQuery, graphql } from 'gatsby';



export const useBilderDataQuery = () => {
    const data = useStaticQuery(graphql`query BilderQuery {
        markdownRemark(frontmatter: {type: {eq: "bilder"}, hero: {childImageSharp: {fluid: {base64: {}}}}}) {
          frontmatter {
            bildtext
            imgtext
            hero {
                childImageSharp {
                base64: sizes(base64Width: 1600, quality: 100) {
                    base64
                    }
                }
            }
            
            iconone {
                childImageSharp {
                    base64: sizes(base64Width: 100, quality: 100) {
                        base64
                    }
                }
            }
            icontwo {
                childImageSharp {
                    base64: sizes(base64Width: 100, quality: 100) {
                        base64
                    }
                }
            }
            iconthree {
                childImageSharp {
                    base64: sizes(base64Width: 100, quality: 100) {
                        base64
                    }
                }
            }
            adplast {
                childImageSharp {
                    base64: sizes(base64Width: 100, quality: 100) {
                        base64
                    }
                }
            }
          }
        }
      }
    `)
    return data.markdownRemark.frontmatter
}
